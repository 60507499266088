import React, { Fragment } from 'react'
import { withSiteData, RouteData, Head } from 'react-static'

import FeaturedArticle from 'components/FeaturedArticle'
import ArticleList from 'components/ArticleList'
import ArticleCard from 'components/ArticleCard'
import Container from 'components/Container'

export default withSiteData(({ title, description, canonicalUrl }) => (
  <RouteData>
    {({ recent, posts }) =>
      pug`
        Fragment
          Head
            title #{title}

            meta(name="description" content=description)

            meta(property="og:type" content="website")

            meta(property="og:description" content=description)

            meta(property="og:title" content=title)

            meta(property="og:url" content=canonicalUrl)

          Container
            if recent
              FeaturedArticle(
              ...recent
              label="Recent"
              )

            if posts.length > 0
              ArticleList
                each post in posts
                  ArticleCard(
                  ...post
                  key=post.sys.id
                  )
      `
    }
  </RouteData>
))
