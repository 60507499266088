import React, { Component } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

import media from 'constants/mediaQuery'
import {
  SIDE_DEFAULT,
  SIDE_DESKTOP,
  SIDE_TABLET,
  SIDE_MOBILE,
  SCOPE_DEFAULT,
  SCOPE_DESKTOP,
  SCOPE_TABLET,
  SCOPE_MOBILE,
  SIDE_Z_INDEX,
} from 'constants/layouts'
import { DURATION, EASE_OUT_CIRC } from 'constants/animations'

const SideBar = styled.div`
  position: fixed;
  top: 0;
  height: 100%;
  width: ${SIDE_DEFAULT}px;
  ${media.desktop`width: ${SIDE_DESKTOP}px`};
  ${media.tablet`width: ${SIDE_TABLET}px`};
  ${media.mobile`width: ${SIDE_MOBILE}px`};
  z-index: ${SIDE_Z_INDEX};
  display: flex;
  justify-content: center;
  align-items: center;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
`

const Scope = styled.div`
  width: ${SCOPE_DEFAULT}px;
  ${media.desktop`width: ${SCOPE_DESKTOP}px`};
  ${media.tablet`width: ${SCOPE_TABLET}px`};
  ${media.mobile`width: ${SCOPE_MOBILE}px`};
  height: 100%;
  overflow: hidden;
`

const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${SCOPE_DEFAULT}px;
  ${media.desktop`width: ${SCOPE_DESKTOP}px`};
  ${media.tablet`width: ${SCOPE_TABLET}px`};
  ${media.mobile`width: ${SCOPE_MOBILE}px`};
  height: 100%;
  transform: rotate(90deg);
  transition: ${DURATION}ms ${EASE_OUT_CIRC};

  &.slided-enter {
    transform: translateX(-${SCOPE_DEFAULT}px) rotate(90deg);
    ${media.desktop`transform: translateX(-${SCOPE_DESKTOP}px) rotate(90deg)`};
    ${media.tablet`transform: translateX(-${SCOPE_TABLET +
      8}px) rotate(90deg)`};
    ${media.mobile`transform: translateX(-${SCOPE_MOBILE +
      4}px) rotate(90deg)`};
  }

  &.slided-enter-done {
    transform: translateX(-${SCOPE_DEFAULT}px) rotate(90deg);
    ${media.desktop`transform: translateX(-${SCOPE_DESKTOP}px) rotate(90deg)`};
    ${media.tablet`transform: translateX(-${SCOPE_TABLET +
      8}px) rotate(90deg)`};
    ${media.mobile`transform: translateX(-${SCOPE_MOBILE +
      4}px) rotate(90deg)`};
  }

  &.slided-appear {
    transform: translateX(-${SCOPE_DEFAULT}px) rotate(90deg);
    ${media.desktop`transform: translateX(-${SCOPE_DESKTOP}px) rotate(90deg)`};
    ${media.tablet`transform: translateX(-${SCOPE_TABLET +
      8}px) rotate(90deg)`};
    ${media.mobile`transform: translateX(-${SCOPE_MOBILE +
      4}px) rotate(90deg)`};
  }

  &.slided-appear-done {
    transform: translateX(-${SCOPE_DEFAULT}px) rotate(90deg);
    ${media.desktop`transform: translateX(-${SCOPE_DESKTOP}px) rotate(90deg)`};
    ${media.tablet`transform: translateX(-${SCOPE_TABLET +
      8}px) rotate(90deg)`};
    ${media.mobile`transform: translateX(-${SCOPE_MOBILE +
      4}px) rotate(90deg)`};
  }
`

export default class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isScrollOnTop: this.isScrollOnTop,
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this)
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this)
    }
  }

  handleEvent() {
    this.setState({ isScrollOnTop: this.isScrollOnTop })
  }

  get isScrollOnTop() {
    if (typeof window !== 'undefined') {
      return (
        !Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop
        ) > 0
      )
    }
  }

  render() {
    const { position, children } = this.props
    const { isScrollOnTop } = this.state
    return pug`
      SideBar(class=position)
        Scope
          CSSTransition(
            in=isScrollOnTop
            classNames="slided"
            timeout=DURATION
            appear
          )
            Box
              = children
    `
  }
}
