import React from 'react'
import styled from 'styled-components'

const Media = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }

  video {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }

  &.filtered {
    filter: brightness(75%);
  }

  &.filtered::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(black 25%, transparent 0),
      radial-gradient(black 25%, transparent 0);
    background-size: 4px 4px;
    opacity: 0.1;
    content: '';
  }
`

export default ({ className, file, description, title }) => pug`
  Media(class=className)
    if file.contentType.split('/')[0] === 'video'
      video(autoPlay muted loop playsInline src=file.url type=file.contentType)

    else if file.contentType.split('/')[0] === 'image'
      img(src=file.url)
`
