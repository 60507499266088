import { css } from 'styled-components'

export const displayWidth = {
  desktop: 1072,
  tablet: 768,
  mobile: 576,
}

const media = Object.keys(displayWidth).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${displayWidth[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export default media
