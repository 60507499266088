import React from 'react'
import styled from 'styled-components'

import AnimatedLink from 'components/AnimatedLink'

import { LIGHTGRAY } from 'constants/colors'

const Footer = styled.div`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${LIGHTGRAY};
  font-size: 12px;
`

export default () => pug`
  Footer
    span.diluted © 2019 
      AnimatedLink(to="/about") solit4ty.io
`
