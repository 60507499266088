export const HEADER_DEFAULT = 256
export const HEADER_REDUCED = 112
export const HEADER_Z_INDEX = 100

export const HERO_DEFAULT = 256
export const HERO_REDUCED = 112

export const SCOPE_DEFAULT = 48
export const SCOPE_DESKTOP = 48
export const SCOPE_TABLET = 24
export const SCOPE_MOBILE = 16

export const SIDE_DEFAULT = 64
export const SIDE_DESKTOP = 64
export const SIDE_TABLET = 48
export const SIDE_MOBILE = 24
export const SIDE_Z_INDEX = HEADER_Z_INDEX - 1

export const LOGO_DEFAULT = 32
export const LOGO_DESKTOP = 32
export const LOGO_TABLET = 24
export const LOGO_MOBILE = 16

export const NAVIGATION_DEFAULT = 16
export const NAVIGATION_DESKTOP = 16
export const NAVIGATION_TABLET = 16
export const NAVIGATION_MOBILE = 10
