export const DURATION_SHORT = 250
export const DURATION = 500
export const EASE_OUT_CIRC = 'cubic-bezier(0.075, 0.82, 0.165, 1)'
export const DELAY = 500
export const REVISION = 50

export default {
  DURATION,
  DURATION_SHORT,
  EASE_OUT_CIRC,
  DELAY,
  REVISION,
}
