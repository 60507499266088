import React from 'react'
import styled from 'styled-components'
import { navigate } from '@reach/router'
import moment from 'moment-timezone'

import Media from 'components/Media'

import marked from 'constants/markdown'
import { WHITE } from 'constants/colors'

const ArticleCard = styled.div`
  width: 256px;
  height: 256px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.7rem;
  position: relative;
  cursor: pointer;
  color: white;
  font-size: ${({ titleLength }) =>
    titleLength
      ? titleLength > 20 && titleLength < 40
        ? 1.1
        : titleLength > 40
        ? 0.9
        : 1.4
      : 1.4}rem;

  &::after {
    position: absolute;
    left: 4px;
    top: 4px;
    font-size: 1.25rem;
    letter-spacing: 0.78em;
    content: '${props => props.date}';
  }
`

const Container = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;

  &:not(:first-child) {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  span {
    margin-left: 4px;
  }
`

const Copyright = styled.div`
  font-size: 10px;
  transform: scale(0.8);
  transform-origin: right;
  position: absolute;
  opacity: 0.25;
  color: ${WHITE};
  bottom: 4px;
  right: 4px;
  letter-spacing: 1px;

  p {
    margin: 0;
  }
  a {
    color: ${WHITE};
    font-weight: 600;
  }
`

const Wrapper = styled.div`
  position: relative;
  transition: 250ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    transform: scale(1.03);
  }
`

export default ({ fields }) => pug`
  Wrapper
    ArticleCard(
    onClick=${() =>
      navigate(`/${fields.category.fields.name}/${fields.articleId}`)}
      date=moment.tz(fields.publishedAt, 'Asia/Tokyo').format('YYYY/MM/DD')
      titleLength=fields.title.length
    ) 
      Container
        Media(...fields.headerImage.fields)
      
      Container
        span ##{fields.category.fields.name}
        
        span #{fields.title}
    
    if fields.headerImage.fields.description
      Copyright(dangerouslySetInnerHTML={__html: marked(fields.headerImage.fields.description)})
`
