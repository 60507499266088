import styled from 'styled-components'
import { Link } from '@reach/router'

import { BLACK, LIGHTGRAY } from 'constants/colors'
import { DURATION, EASE_OUT_CIRC } from 'constants/animations'

const AnimatedLink = styled(Link)`
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: ${BLACK};

  &:visited {
    color: ${BLACK};
  }

  .diluted & {
    color: ${LIGHTGRAY};
  }

  &:hover {
    color: ${BLACK};
    transition: ${DURATION}ms ${EASE_OUT_CIRC};
  }

  &::after {
    position: absolute;
    left: 0;
    bottom: 50%;
    width: 100%;
    height: 2px;
    background-color: ${BLACK};
    transform: scaleX(0);
    transform-origin: left top;
    transition: transform 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
    content: '';
  }

  &.large::after {
    height: 3px;
  }

  &:hover::after {
    transform: scaleX(1);
  }
`

export default AnimatedLink
