import React from 'react'
import styled from 'styled-components'

const ArticleList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 256px);
  gap: 16px;
  justify-content: center;
  justify-items: center;
  letter-spacing: 1em;
  margin-bottom: 64px;
`

export default ({ children }) => pug`
  ArticleList #{children}
`
