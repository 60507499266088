import React from 'react'
import styled from 'styled-components'
import { navigate } from '@reach/router'
import moment from 'moment-timezone'

import AnimatedLink from 'components/AnimatedLink'
import Media from 'components/Media'

import marked from 'constants/markdown'
import { displayWidth } from 'constants/mediaQuery'
import { GRAY, LIGHTGRAY, WHITE } from 'constants/colors'
import { DURATION_SHORT, EASE_OUT_CIRC } from 'constants/animations'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px 0 16px;

  &::after {
    content: '';
    width: 100%;
    max-width: ${displayWidth.desktop}px;
    margin: 64px 0 64px 0;
    border-bottom: solid ${LIGHTGRAY} 1px;
  }
`

const FeaturedArticle = styled.div`
  width: 512px;
  max-width: 100%;
  cursor: pointer;
  transition: ${DURATION_SHORT}ms ${EASE_OUT_CIRC};

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`

const Category = styled.div`
  letter-spacing: 0.75rem;
  margin-bottom: 1em;
  font-size: 0.75rem;
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
`

const Label = styled.div`
  letter-spacing: 0.25em;
  font-weight: 700;
  margin-bottom: 2em;
  font-size: 20px;
`

const Title = styled(AnimatedLink)`
  letter-spacing: 0.3rem;
  font-size: calc(90% + 0.6vw);
  font-weight: 600;
`

const Date = styled.span`
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.5rem;
  color: ${GRAY};
  margin-bottom: 8px;
`

const Copyright = styled.div`
  width: 512px;
  max-width: 100%;
  font-size: 10px;
  transform: scale(0.8);
  transform-origin: right;
  position: relative;
  opacity: 0.25;
  color: ${WHITE};

  p {
    position: absolute;
    top: -16px;
    right: 4px;
    margin: 0;
  }
  a {
    color: ${WHITE};
    font-weight: 600;
  }
`

export default ({ fields, label }) => pug`
  Wrapper
    Label
      span #{label}
    
    Category
      AnimatedLink(to=${`/${
        fields.category.fields.name
      }`}) ##{fields.category.fields.name}
      
    FeaturedArticle(
      onClick=${() =>
        navigate(`/${fields.category.fields.name}/${fields.articleId}`)}
      )      
      Media(
        ...fields.headerImage.fields
      )
    
    if fields.headerImage.fields.description
      Copyright(dangerouslySetInnerHTML={__html: marked(fields.headerImage.fields.description)})
        
    Text
      Date #{moment.tz(fields.publishedAt, 'Asia/Tokyo').format('YYYY/MM/DD')}
      
      Title(
        to=${`/${fields.category.fields.name}/${
          fields.articleId
        }`}) #{fields.title}
`
