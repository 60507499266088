import React from 'react'
import { withSiteData } from 'react-static'
import { CSSTransition } from 'react-transition-group'
import { Location } from '@reach/router'
import styled, { keyframes } from 'styled-components'

import AnimatedLink from 'components/AnimatedLink'

import media from 'constants/mediaQuery'
import {
  LOGO_DEFAULT,
  LOGO_DESKTOP,
  LOGO_MOBILE,
  LOGO_TABLET,
} from 'constants/layouts'

const DURATION = 500
const DELAY = 500
const REVISION = 50

const Logo = styled.div`
  font-size: ${LOGO_DEFAULT}px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &.diluted {
    ${media.desktop`font-size: ${LOGO_DESKTOP}px`}
    ${media.tablet`font-size: ${LOGO_TABLET}px`}
    ${media.mobile`font-size: ${LOGO_MOBILE}px`}
  }

`

const slideIn = keyframes`
  from {
    flex: 0;
  }

  to {
    flex: 1;
  }
`

const PathName = styled.div`
  overflow: hidden;
  flex: 1;

  &.nested-enter {
    flex: 0;
    animation: ${slideIn} ${DURATION}ms cubic-bezier(0.075, 0.82, 0.165, 1)
      ${DELAY}ms;
  }

  &.nested-appear {
    flex: 0;
    animation: ${slideIn} ${DURATION}ms cubic-bezier(0.075, 0.82, 0.165, 1)
      ${DELAY}ms;
  }
`

const parseUrl = (url, categories) => {
  const parsed = url.split('/')
  const category = categories.find(({ fields }) => fields.name === parsed[1])
  return category ? category.fields.name : ''
}

export default withSiteData(({ categories, title, className }) => (
  <Location>
    {({ location }) => {
      const categoryName = parseUrl(location.pathname, categories)
      return pug`
        Logo(class=className)
          AnimatedLink.large(to="/") #{title}

          CSSTransition(
            key=location.key
            in=!!categoryName
            classNames="nested"
            timeout=DURATION + DELAY - REVISION
            appear
            unmountOnExit
          )
            PathName
              AnimatedLink.large(to="/" + categoryName) ##{categoryName}
      `
    }}
  </Location>
))
