import styled from 'styled-components'

import {
  SIDE_DEFAULT,
  SIDE_DESKTOP,
  SIDE_TABLET,
  SIDE_MOBILE,
  HEADER_DEFAULT,
} from 'constants/layouts'
import media from 'constants/mediaQuery'
import { DURATION, EASE_OUT_CIRC } from 'constants/animations'

export default styled.div`
  padding: 0 ${SIDE_DEFAULT}px;
  ${media.desktop`padding: 0 ${SIDE_DESKTOP}px`};
  ${media.tablet`padding: 0 ${SIDE_TABLET}px`};
  ${media.mobile`padding: 0 ${SIDE_MOBILE}px`};
  width: calc(100% - ${SIDE_DEFAULT * 2}px);
  ${media.desktop`width: calc(100% - ${SIDE_DESKTOP * 2}px)`};
  ${media.tablet`width: calc(100% - ${SIDE_TABLET * 2}px)`};
  ${media.mobile`width: calc(100% - ${SIDE_MOBILE * 2}px)`};
  margin-top: ${HEADER_DEFAULT}px;
  min-height: calc(100vh - ${HEADER_DEFAULT}px);
  transition: ${DURATION}ms ${EASE_OUT_CIRC};
  //margin-bottom: 5em;
`
