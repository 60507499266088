import React from 'react'
import { Root, Routes } from 'react-static'
import { createGlobalStyle } from 'styled-components'

import { GRAY } from 'constants/colors'

import Header from 'components/Header'
import Footer from 'components/Footer'
import SideBar from 'components/SideBar'
import Logo from 'components/Logo'

const GlobalStyle = createGlobalStyle`
  @import url('https://indestructibletype-fonthosting.github.io/renner.css');

  body {
    font-family: 'Renner*', Futura, 'Trebuchet MS', Arial, "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: ${GRAY};
    letter-spacing: 0.15em;
    margin: 0;
    padding: 0;
  }
  tt, code, kbd, samp {
    font-family: 'Myrica P', Menlo, Consolas, 'DejaVu Sans Mono', monospace;
  }

`

const App = () =>
  pug`
    Root
      Header
      
      SideBar(position="left")
        Logo.diluted

      Routes

      SideBar(position="right")
      
      Footer
        
      GlobalStyle
  `

export default App
