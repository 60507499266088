import marked, { Renderer } from 'marked'
import hljs from 'highlight.js'

const renderer = new Renderer()

renderer.code = (code, fileInfo) => {
  if (!fileInfo) {
    return `<pre><code class="hljs">${
      hljs.highlightAuto(code).value
    }</code></pre>`
  }
  const info = fileInfo.split(':')
  return `<pre><code class="hljs ${renderer.options.langPrefix}${info[0]}">${
    hljs.highlightAuto(code).value
  }</code></pre>`
}

renderer.link = (href, title, text) =>
  `<a target="_blank" href="${href}" title="${title}">${text}</a>`

marked.setOptions({
  sanitize: true,
  breaks: true,
  renderer,
})

export default marked
