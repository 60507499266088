export const WHITE = '#ffffff'
export const GRAY = '#454545'
export const BLACK = '#292929'
export const LIGHTGRAY = '#ececec'

export default {
  WHITE,
  BLACK,
  GRAY,
  LIGHTGRAY,
}
