import React, { Component } from 'react'
import { Location } from '@reach/router'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

import Navigation from 'components/Navigation'
import Logo from 'components/Logo'
import AnimatedLink from 'components/AnimatedLink'

import {
  HEADER_DEFAULT,
  HEADER_REDUCED,
  SCOPE_DEFAULT,
  HEADER_Z_INDEX,
} from 'constants/layouts'
import { DURATION, EASE_OUT_CIRC } from 'constants/animations'
import { WHITE } from 'constants/colors'

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  height: ${HEADER_REDUCED}px;
  width: 100%;
  z-index: ${HEADER_Z_INDEX};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${WHITE};
  transition: ${DURATION}ms ${EASE_OUT_CIRC};

  &.top-enter {
    height: ${HEADER_DEFAULT}px;
  }

  &.top-enter-done {
    height: ${HEADER_DEFAULT}px;
  }

  &.top-appear {
    height: ${HEADER_DEFAULT}px;
  }

  &.top-appear-done {
    height: ${HEADER_DEFAULT}px;
  }
`

const Scope = styled.div`
  height: ${SCOPE_DEFAULT}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow: hidden;
`

const Box = styled.div`
  transition: ${DURATION}ms ${EASE_OUT_CIRC};
  &.slided-enter {
    transform: translateY(-${SCOPE_DEFAULT}px);
  }
  &.slided-enter-done {
    transform: translateY(-${SCOPE_DEFAULT}px);
  }
  &.slided-appear {
    transform: translateY(-${SCOPE_DEFAULT}px);
  }
  &.slided-appear-done {
    transform: translateY(-${SCOPE_DEFAULT}px);
  }
`

const Block = styled.div`
  height: ${SCOPE_DEFAULT}px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Author = styled.div`
  transition: ${DURATION}ms ${EASE_OUT_CIRC};
  height: 0;
  overflow: hidden;
  font-size: 12px;

  &.top-enter {
    height: 16px;
  }

  &.top-enter-done {
    height: 16px;
  }

  &.top-appear {
    height: 16px;
  }

  &.top-appear-done {
    height: 16px;
  }
`

const parseUrl = url => {
  const parsed = url.split('/')
  return !!parsed[2]
}

export default class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isScrollOnTop: this.isScrollOnTop,
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this)
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this)
    }
  }

  handleEvent() {
    this.setState({ isScrollOnTop: this.isScrollOnTop })
  }

  get isScrollOnTop() {
    if (typeof window !== 'undefined') {
      return (
        !Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop
        ) > 0
      )
    }
  }

  render() {
    const { isScrollOnTop } = this.state
    return (
      <Location>
        {({ location }) =>
          pug`
            CSSTransition(
            in=${isScrollOnTop && !parseUrl(location.pathname)}
            classNames="top"
            timeout=DURATION
            appear
            )
              Header
                Scope
                  CSSTransition(
                  in=!isScrollOnTop
                  classNames="slided"
                  timeout=DURATION
                  appear
                  )
                    Box
                      Block
                        Logo

                      if !parseUrl(location.pathname)
                        Block
                          Navigation

                CSSTransition(
                  in=isScrollOnTop
                  classNames="top"
                  timeout=DURATION
                  appear
                  )
                  Author
                    span #{'by '}
                    AnimatedLink(to="/about") solit4ry.io
          `
        }
      </Location>
    )
  }
}
