import React from 'react'
import { withSiteData } from 'react-static'
import styled from 'styled-components'

import AnimatedLink from 'components/AnimatedLink'

import media from 'constants/mediaQuery'
import {
  NAVIGATION_DEFAULT,
  NAVIGATION_DESKTOP,
  NAVIGATION_TABLET,
  NAVIGATION_MOBILE,
} from 'constants/layouts'

const Navigation = styled.div`
  min-height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  letter-spacing: 1em;
  font-size: ${NAVIGATION_DEFAULT}px;
  ${media.desktop`font-size: ${NAVIGATION_DESKTOP}px`};
  ${media.tablet`font-size: ${NAVIGATION_TABLET}px`};
  ${media.mobile`font-size: ${NAVIGATION_MOBILE}px`};
`

export default withSiteData(
  ({ categories }) => pug`
    Navigation
      each category in categories
        AnimatedLink(to="/" + category.fields.name key=category.sys.id) ##{category.fields.name}
`
)
